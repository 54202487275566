import {FC} from 'react'
import PartnersPage from "../containers/PartnersPage";
import RequisitesPage from "../containers/RequisitesPage";
import ContactsPage from "../containers/ContactsPage";
import ReportingPage from "../containers/ReportingPage";
import HomePage from "../containers/HomePage/HomePage";

interface IRoutes {
    path: string,
    element: FC
}

export const pageRoutes: IRoutes[] = [
    {
        path: '/',
        element: HomePage,
    },
    {
        path: '/partners',
        element: PartnersPage,
    },
    {
        path: '/reporting',
        element: ReportingPage,
    },
    {
        path: '/requisites',
        element: RequisitesPage,
    },
    {
        path: '/contacts',
        element: ContactsPage,
    },
];
