import {FC} from "react";
import cn from 'classnames'

import Slider from "../../components/Slider";

import icon from './img/check_circle.svg'

import classes from './HomePage.module.css'

const HomePage: FC = () => {
    return (
        <main>
            <Slider />
            <section className={cn(classes.section, classes.sectionTitle)}>
                <div className='container'>
                    <p className={classes.center}>Благодійна організація «Міжнародний благодійний фонд «Українська громада» є недержавною добровільною благодійною
                        організацією, утвореною у формі благодійного фонду, що заснована і діє відповідно до Конституції
                        України, Закону України «Про благодійну діяльність та благодійні організації», чинного
                        законодавства
                        України та цього Статуту та при здійсненні своєї діяльності не має на меті одержання
                        прибутку.</p>
                </div>
            </section>
            <section className={cn(classes.section, classes.sectionBGBlue, classes.sectionGoals)}>
                <div className='container'>
                    <p className={classes.center}><b>Основною метою благодійної діяльності МБФ «Українська громада»</b> є
                        надання благодійної допомоги постраждалим внаслідок катастроф, збройних конфліктів і нещасних
                        випадків, а також біженцям та особам, які перебувають у складних життєвих обставинах.</p>
                    <div>
                        <h4>Основні цілі діяльності фонду:</h4>
                        <ul>
                            <li><img src={icon} alt=""/>
                                <p>здійснення благодійної діяльності в інтересах громадян, суспільства;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>надання допомоги та підтримки ЗСУ та іншим законним формуванням оборони України;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>провадження благодійної діяльності у сферах організації, створення, підтримки та
                                    розвитку
                                    закладів культури, науки, мистецтва та освіти;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>створення простору для самореалізації дітей та молоді;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>пропаганда здорового способу життя;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>надання допомоги дітям-сиротам, дітям з малозабезпечених та неблагополучних сімей,
                                    дітям з інвалідністю;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>надання допомоги українцям, які перебувають у вимушеній еміграції;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>фінансування проєктів із підтримки існуючих та створення і будівництва нових
                                    закладів,
                                    стадіонів, майданчиків, дитячих будинків та ін.;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>будівництво та придбання елементів інфраструктури, житлових будівель, земельних
                                    ділянок
                                    тощо як на території України, так і на території інших держав для розміщення
                                    постраждалих внаслідок катастроф, збройних конфліктів та нещасних випадків;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>фінансування проєктів із реставрації та відбудови постраждалої внаслідок збройних
                                    конфліктів інфраструктури та житлових споруд;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>фінансування реставрації та будівництва соціального житла;</p>
                            </li>
                            <li><img src={icon} alt=""/>
                                <p>фінансування будівництва споруд соціального призначення.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className={cn(classes.section, classes.sectionBGLight)}>
                <div className='container'>
                    <h4>Основні форми здійснення фондом благодійної діяльності:</h4>
                    <ol>
                        <li>Надання одноразової фінансової, матеріальної, організаційної та іншої допомоги (в тому числі
                            надання грантів).
                        </li>
                        <li>Надання систематичної фінансової, матеріальної, організаційної та іншої допомоги.</li>
                        <li>Фінансування конкретних цільових програм.</li>
                        <li>Надання допомоги на підставі укладених договорів (контрактів) про благодійну діяльність.
                        </li>
                        <li>Дарування або надання дозволу на безоплатне (пільгове) використання (користування) об’єктів
                            власності.
                        </li>
                        <li>Надання допомоги особистою працею, послугами чи передачею результатів особистої творчої
                            діяльності працівників Фонду або осіб, залучених Фондом на договірних засадах, набувачам.
                        </li>
                        <li>Прийняття на себе витрат по безоплатному, повному або частковому утриманню об’єктів
                            благодійництва.
                        </li>
                        <li>Участь у наданні грантів, інших благодійних пожертв і фінансової допомоги фондам громад, а
                            також сприяння наданню такої допомоги фондам громад від фізичних і юридичних осіб,
                            міжнародних організацій.
                        </li>
                        <li>Сприяння меценатській діяльності, обміну і поширенню інформації, пов’язаних з метою і цілями
                            Фонду.
                        </li>
                        <li>Участь у дорадчих органах, громадських обговореннях, консультаціях, експертизі
                            нормативно-правових актів, а також заходах з моніторингу та оцінки, що стосуються мети і
                            цілей Фонду.
                        </li>
                        <li>Надання дозволу на використання своєї назви, емблеми, символіки.</li>
                        <li>Публічний збір благодійних пожертв.</li>
                        <li>Управління благодійними ендавментами.</li>
                        <li>Виконання заповітів, заповідальних відказів і спадкових договорів для благодійної
                            діяльності.
                        </li>
                        <li>Проведення благодійних аукціонів, негрошових лотерей, конкурсів та інших благодійних
                            заходів, не заборонених законом.
                        </li>
                    </ol>
                </div>
            </section>
        </main>
    )
}

export default HomePage
