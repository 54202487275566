import {FC} from "react";

import classes from "./PartnersPage.module.css";

const PartnersPage: FC = () => {
    return (
        <main className='container'>
            <h2 className={classes.title}>Partners</h2>
            <section className={classes.section}>
                <div>
                    <img src={require('./img/diaspora.jpeg')} alt=""/>
                    <span>Українська діаспора за кордоном</span>
                </div>
                <div>
                    <img src={require('./img/medical.jpeg')} alt=""/>
                    <span>Медичні установи України</span>
                </div>
                <div>
                    <img src={require('./img/zsy.png')} alt=""/>
                    <span>Збройні сили України</span>
                </div>
                <div>
                    <img src={require('./img/miu.png')} alt=""/>
                    <span>Міністерство інфраструктури України</span>
                </div>
                <div>
                    <img src={require('./img/mozu.jpeg')} alt=""/>
                    <span>Міністерство охорони здоровʼя України</span>
                </div>
                <div>
                    <img src={require('./img/mou.png')} alt=""/>
                    <span>Міністерство оборони України</span>
                </div>
            </section>
        </main>
    )
}

export default PartnersPage
