import {FC} from "react";

import googlePayIcon from './img/google_pay.svg';
import applePayIcon from './img/apple_pay.svg';

import classes from './RequisitesPage.module.css'

const RequisitesPage: FC = () => {
    return (
        <main className='container'>
            <h2 className={classes.title}>РЕКВІЗИТИ</h2>
            <section className={classes.section}>
                <div className={classes.donationButtons}>
                    <a href="https://help.uagromada.org/your-help" target='_blank' rel="noreferrer"><span>Пожертвувати з</span><img src={googlePayIcon} alt="Google Play" /></a>
                    <a href="https://help.uagromada.org/your-help" target='_blank' rel="noreferrer"><span>Пожертвувати з</span><img src={applePayIcon} alt="Apple Store"/></a>
                </div>
                <div>
                    <h4>Банківські рахунки</h4>
                    <ol>
                        <li><a href="https://help.uagromada.org/your-help" target='_blank' rel="noreferrer">Для сплати банківською картою</a></li>
                        <li>EUR: LT497189900009700848 (AB Šiaulių bankas)</li>
                    </ol>
                </div>
                <div>
                    <h4>Криптогаманці</h4>
                    <ol>
                        <li>BTC: bc1qgu2dy2p6f93hl0yuxs64zpyq4l0l79ylf4r8af</li>
                        <li>ETH: 0xbc9471f8C0AC14aE681F2d79178e1eE02CD3fF7a</li>
                        <li>USDT ECR20: 0xbc9471f8C0AC14aE681F2d79178e1eE02CD3fF7a</li>
                    </ol>
                </div>
                <div>
                    <h4>Юридичні реквізити Фонду</h4>
                    <ol>
                        <li>
                            <span>ОПФ:</span>
                            <span>Благодійна організація</span>
                        </li>
                        <li>
                            <span>Назва:</span>
                            <span>UA GROMADA</span>
                        </li>
                        <li>
                            <span>Код підприємства:</span>
                            <span>306145977</span>
                        </li>
                        <li>
                            <span>Юридична адреса:</span>
                            <span>Švitrigailos g. 11K-109, LT-03228 Vilnius </span>
                        </li>
                        <li>
                            <span>Директор:</span>
                            <span>Andrius Misiukas</span>
                        </li>
                    </ol>
                </div>
            </section>
        </main>
    )
}

export default RequisitesPage;
