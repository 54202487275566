import {FC} from "react";

import classes from './ReportingPage.module.css'

const ReportingPage: FC = () => {
    return (
        <main className='container'>
            <h2 className={classes.title}>ЗВІТНІСТЬ</h2>
            <section className={classes.section}>
                <h4>Звітність за 2022 рік</h4>
                <p>Повний звіт за 2022 рік очікується на сайті до 09 лютого 2023 року ...</p>
                <div>
                    <p>Фото-, відеозвіти можна переглянути за <a href="https://instagram.com/uagromada" rel="noreferrer" target="_blank">посиланням</a></p>
                </div>
            </section>
        </main>
    )
}

export default ReportingPage
