import {Routes, Route} from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import {pageRoutes} from "../../routes/pageRoutes";

import classes from './App.module.css';

const App = () => {
    return (
        <div className={classes.container}>
            <Header />
            <Routes>
                {pageRoutes.map(({path, element: Element}, key) =>
                    <Route key={key} path={path} element={<Element/>}/>
                )}
            </Routes>
            <Footer />
        </div>
    )
}

export default App;
