import {FC, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import cn from 'classnames';

import logo from './img/logo.png'
import menu from './img/menu.svg'

import classes from './Header.module.css'
import Drawer from "../Drawer";


const Header: FC = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    return (
        <>
            <header className={classes.header}>
                <div className={cn('container', classes.headerTop)}>
                    <Link to='/'>
                        <img src={logo} alt="Logo" className={classes.logo}/>
                    </Link>
                    <div className={classes.buttons}>
                        <Link to='/requisites'>Зробити внесок</Link>
                        <a href='https://help.uagromada.org/need-help' rel="noreferrer">Попросити допомоги</a>
                    </div>

                    <button className={classes.menu} onClick={() => setShowDrawer(true)}>
                        <img src={menu} alt=""/>
                    </button>
                </div>
                <nav className={classes.navWrapper}>
                    <ul className={cn('container', classes.nav)}>
                        <li>
                            <NavLink to='/'>Про фонд</NavLink>
                        </li>
                        <li>
                            <NavLink to='/partners'>Партнери</NavLink>
                        </li>
                        <li>
                            <NavLink to='/reporting'>Звітність</NavLink>
                        </li>
                        <li>
                            <NavLink to='/requisites'>Реквізити</NavLink>
                        </li>
                        <li>
                            <NavLink to='/contacts'>Контакти</NavLink>
                        </li>
                    </ul>
                </nav>
            </header>
            <Drawer show={showDrawer} onClose={setShowDrawer}/>
        </>
    )
}

export default Header;
