import {FC} from "react";
import Slick from 'react-slick';
import cn from 'classnames'

import slider1 from './img/slider1.jpg'
import gromada from './img/gromada.png'

import classes from './Slider.module.css';

const Slider: FC = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    return (
        <Slick {...settings}>
            <div className={cn(classes.item)}>
                <div className={cn(classes.itemBG, classes.slider1)}>
                    <img src={slider1} alt=""/>
                    <h3 className={cn(classes.itemTitle, classes.titleBlack)}>
                        <img src={gromada} alt=""/>
                    </h3>
                </div>
            </div>
            <div className={classes.item}>
                <div className={cn(classes.itemBG, classes.slider2)}>
                    <img src={slider1} alt=""/>
                    <h3 className={cn(classes.itemTitle, classes.titleWhite)}>Допомога постраждалим внаслідок катастроф,
                        збройних конфліктів і нещасних випадків, а також біженцям та особам, які перебувають у складних
                        життєвих обставинах.</h3>
                </div>
            </div>
            <div className={classes.item}>
                <div className={cn(classes.itemBG, classes.slider3)}>
                    <img src={slider1} alt=""/>
                    <h3 className={cn(classes.itemTitle, classes.titleBlack)}>Надання допомоги та підтримки ЗСУ та іншим
                        законним формуванням оборони України</h3>
                </div>
            </div>
        </Slick>
    )
}

export default Slider
